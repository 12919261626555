@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600;700;800&display=swap");

body {
	@apply bg-blue;
}

.brush-underline::after {
	content: "";
	position: absolute;
	top: 90%;
	left: 0;
	right: 0;
	background-image: url("../src/assets/icons/brush-underline.svg");
	background-repeat: no-repeat;
	background-size: contain;
	width: 100%;
	height: 100%;
}

select {
	@apply appearance-none;
	background-image: url("../src/assets/icons/select-arrow.svg");
	background-repeat: no-repeat, repeat;
	background-position: right 1rem top 50%, 0 0;
	background-size: auto auto, 100%;
}

input[type="search"] {
	@apply appearance-none;
	background-image: url("../src/assets/icons/search-icon_blue-sm.svg");
	background-repeat: no-repeat, repeat;
	background-position: right 1rem top 50%, 0 0;
	background-size: auto auto, 100%;
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}
input[type="search"]::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	display: none;
}

input[type="checkbox"]:checked + div svg {
	@apply block;
}
